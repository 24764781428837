import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { User } from 'src/app/model/User';
import { LoginService } from 'src/app/services/login.service';

export interface MenuEntry {
  [x: string]: any;
  slug?: string;
  label: string;
  onClick?: CallableFunction;
  route?: string;
  submenu?: MenuEntry[]; // Add this line
}

@Component({
  selector: 'c-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public exists: boolean = true;
  public role: string = '';
  isOpen = true;
  public entries: MenuEntry[] = [
    {
      slug: 'home',
      label: 'Home',
      route: '/',
    },
    {
      slug: 'users',
      label: 'Users',
      route: '/users',
     
    },
    {
      slug: 'clients',
      label: 'Clients',
      route: '/clients',
    },
    {
      slug: 'emails',
      label: 'Emails',
      route: '/emails',
    },
    {
      slug: 'depots',
      label: 'Depots',
      route: '/depots',
    },
    {
      slug: 'location-data',
      label: 'Location Data',
      route: '/location-data',
    },
    {
      slug: 'posts',
      label: 'Posts',
      route: '/posts',
    },
    {
      slug: 'logs',
      label: 'Logs',
      route: '/logs',
    },
    {
      slug: 'summaries',
      label: 'Summaries',
      route: '/summaries',
    },
    {
      slug: 'pod',
      label: 'POD',
      route: '/pod',
    },
    {
      slug: 'timesheets',
      label: 'TIMESHEETS',
      route: '/timesheets',
    },
    {
      slug: 'offers',
      label: 'OFFERS',
      route: '#',
      submenu: [
        {
          slug: 'dedi-shift-offer',
          label: 'DEDI SHIFT OFFERS',
          route: '/offers',
        },
        {
          slug: 'flexi-job-offer',
          label: 'FLEXI JOB OFFERS',
          route: '/offers/flexijoboffers',
        }
      ]
    },
    // { slug: 'shifts', label: 'Shifts', route: '/shifts' },
    {
      slug: 'settings',
      label: 'Settings',
      route: '/settings',
    },
    {
      slug: 'logout',
      label: 'Logout',
      onClick: () => {
        localStorage.removeItem('access_token');
        this.router.navigate(['/login']);
      },
    },
  ];

  public guestEntries: MenuEntry[] = [
    {
      slug: 'home',
      label: 'Home',
      route: '/',
    },
    {
      slug: 'logout',
      label: 'Logout',
      onClick: () => {
        localStorage.removeItem('access_token');
        this.router.navigate(['/login']);
      },
    },
  ];

  public activeSlug = 'home';
  public userRole = 'Guest';

  private subs: Subscription[] = [];

  public static show = new EventEmitter<boolean>();
  public static slug = new EventEmitter<string>();

  constructor(
    private loginService: LoginService,
    private cdref: ChangeDetectorRef,
    private jwt: JwtHelperService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (!this.currUser()) this.exists = false;
    this.subs.push(
      MenuComponent.show.subscribe((r) => {
        this.exists = r;
      }),
      MenuComponent.slug.subscribe((r) => {
        this.activeSlug = r;
      })
    );
  }

  ngOnInit(): void {
    this.loginService.globalVariable$.subscribe((value) => {
      this.userRole = value;
    });
  }


  public toggleSubmenu(entry: MenuEntry) {
    // Close other submenus if needed
    this.entries.forEach(e => {
      if (e !== entry) {
        e.isOpen = false;
      }
    });
    // Toggle the selected submenu
    entry.isOpen = !entry.isOpen;
  }

  
  public currUser() {
    let user = this.jwt.decodeToken()?.user;
    if (!user) return null;
    return User.mapUser(this.jwt.decodeToken().user);
  }

  public static display(show: boolean) {
    this.show.emit(show);
  }

  public static setActive(slug: string) {
    this.slug.emit(slug);
  }
}
