import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { googleApiKey } from "src/app/settings/settings";

@Injectable({
    providedIn: 'root',
})
export class AddressGeocoder {
    constructor(private http: HttpClient) {}

    private cleanAddress(address: string): string {
        // Regex: remove the prefixes and its corresponding number. Geocoding doesn't need it and it only makes the search less accurate.
        const prefixPattern = /^(?:Level|Unit|Apartment|Suite|Flat|Room|Door|Ground Floor|Penthouse|Corner|Lvl|U|Apt|Ste|Flt|Rm|Gnd Flr|Pent)\s*\d*[\/,]?\s*|\b(\d+)\/(\d+)/i;
        // Remove the prefixes
        address = address.replace(prefixPattern, '');
        return address.trim();
    }

    public async geoCodeAddress(address: string): Promise<{ lat: number, lng: number } | null> {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json`;
        try {
            const response = await this.http.get<any>(geocodeUrl, {
                params: {
                    "address": this.cleanAddress(address),
                    "key": googleApiKey,
                    "language": "en",
                    "region": "AU",
                    "components": "country:AU",
                }
            }).toPromise();
    
            const result = response?.results?.[0];
            if (!result) {
                return null;
            }
    
            const { lat, lng } = result.geometry.location;
            return { lat, lng };
        } catch (error) {
            console.error('Geocoding error:', error);
            return null;
        }
    }
}