<div class='w-full shift-offer-height max-h-screen scroll-thin-style flex flex-col'>
    <div class="timesheet_drawer" [ngClass]="{
        drawer_appear: isExpanded.drawer,
        drawer_disappear: !isExpanded.drawer
      }">
        <div class="timesheet_drawer_content">
            <div class="timesheet_drawer_header">
                <div>
                    <span>Filters</span>
                    <span (click)="handleVisivility('drawer', false)">
                        <img src="/assets/icons/x-close.svg" alt="search-icon" /></span>
                </div>
                <span>Select filters to refine data.</span>
            </div>
            <div class="timesheet_drawer_body scroll-thin-style">
                <div class="timesheet_states scroll-thin-style">
                    <div class="timesheet_state_header" (click)="handleVisivility('state')">
                        <ng-container *ngIf="isExpanded.state">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.state">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Location</span>
                    </div>
                    <div class="timesheet_state_content" *ngIf="isExpanded.state">
                        <div *ngFor="
                  let state of businessDetails.state;
                  let i = index
                ">
                            <mat-checkbox color="primary" [checked]="isChecked(state.isChecked)"
                                (change)="setFilters($event.checked, 'state', state)">
                                {{ state.state }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="timesheet_states scroll-thin-style">
                    <div class="timesheet_state_header" (click)="handleVisivility('department')">
                        <ng-container *ngIf="isExpanded.department">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.department">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Department</span>
                    </div>
                    <div class="department_search" *ngIf="isExpanded.department">
                        <div class="rounded_input_wrap">
                            <img src="/assets/icons/search.svg" alt="search-icon" />
                            <input class="none_border_outline" [(ngModel)]="departmentSearch"
                                (ngModelChange)="searchFilterOption($event,'department')"
                                style="border: none; outline: none" placeholder="Quick search" />
                        </div>
                    </div>
                    <div class="timesheet_state_content timesheet_departments scroll-thin-style"
                        *ngIf="isExpanded.department">
                        <div *ngFor="
                  let department of filterDepartments(
                    businessDetails.department
                  );
                  let i = index
                " class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(department.isChecked)"
                                (change)="setFilters($event.checked, 'department', department)">
                                {{ department.department }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="timesheet_states" *ngFor="let option of filterOptions;let i=index;">
                    <div class="timesheet_state_header" (click)="handleVisivility(option.key)">
                        <ng-container *ngIf="isExpanded[option.key]">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded[option.key]">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>{{option.label}}</span>
                    </div>
                    <div class="timesheet_state_content timesheet_flaggs scroll-thin-style"
                        *ngIf="isExpanded[option.key]">
                        <div *ngFor=" let value of  businessDetails[option.key];let i = index" class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(value.isChecked)"
                                (change)="setFilters($event.checked, option.key, value)">
                                {{filterOptionValues[option.key][value[option.key]] }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="timesheet_drawer_footer">
                <span (click)="handleClearAllChecked()">Clear Filters</span>
                <div>
                    <button class="filter_cancel" (click)="handleVisivility('drawer', false)">
                        Cancel
                    </button>
                    <button class="filter_apply" (click)="handleFilterApply()">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full flex flex-col gap-4 px-5 pt-10 box-border">
        <div class="search_filter_wrap">
            <div class="align_center">
                <div class="rounded_input_wrap">
                    <img src="/assets/icons/search.svg" alt="search-icon" />
                    <input class="none_border_outline" [ngModel]="query.filters!.search"
                        (ngModelChange)="searcherInput($event)" style="border: none; outline: none"
                        placeholder="Search" />
                </div>
                <div class='pod_filters' (click)="handleVisivility('drawer')">
                    <img src="/assets/icons/filter-lines.svg" alt="filter-lines-icon" />
                    <span>Filters</span>
                </div>
            </div>
            <div class="search_option_wrap">
                <div class="date_range_selection">
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="fromDate"
                                    (ngModelChange)="handleFromDateChange($event)" />
                                <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(fromDate)
                                }}</span>
                        </span>
                        <button (click)="picker.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" [(ngModel)]="toDate"
                                    (ngModelChange)="handleToDateChange($event)" />
                                <mat-datepicker #picker1><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(toDate)
                                }}</span>
                        </span>
                        <button (click)="picker1.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                    <div class='w-fit h-fit flex items-center gap-5'>
                        <button (click)="selectDefaultDay('yesterday')" [ngClass]="{'bg-[#2A6AF5] text-white':defaultDay==='yesterday',
                            'bg-[#ECECEC] text-[#979898]':defaultDay!=='yesterday'}"
                            class='flex justify-center items-center text-sm font-semibold font-Inter border-none rounded-lg box-border px-[14px] py-2'>
                            Yesterday
                        </button>
                        <button (click)="selectDefaultDay('today')" [ngClass]="{'bg-[#2A6AF5] text-white':defaultDay==='today',
                            'bg-[#ECECEC] text-[#979898]':defaultDay!=='today'}"
                            class='flex justify-center items-center text-sm font-semibold font-Inter border-none bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                            Today
                        </button>
                        <button (click)="selectDefaultDay('last_week')" [ngClass]="{'bg-[#2A6AF5] text-white':defaultDay==='last_week',
                            'bg-[#ECECEC] text-[#979898]':defaultDay!=='last_week'}"
                            class='flex justify-center items-center text-sm font-semibold font-Inter border-none bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                            Last Week
                        </button>
                    </div>
                </div>
                <div class="filtered_option_remove" *ngIf="checkForFilter()">
                    <span (click)="handleClearAllFilters()">Clear Filters</span>
                </div>
            </div>
            <div class='flex flex-1 justify-end items-center'>
                <button *ngxPermissionsOnly="['full:timesheets','edit:timesheets', 'edit-process-payments:timesheets']"
                    (click)="openPaymentConfirmation()"
                    class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                    Process Payments
                </button>
            </div>
        </div>
        <div class='w-full flex items-center' [ngClass]="{'justify-between':data.length,'justify-start':!data.length}">
            <div class='flex gap-2 items-center w-fit justify-start'>
                <button *ngxPermissionsOnly="['full:timesheets', 'create:timesheets']"
                    (click)="handleCreateNewTimesheet()"
                    class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                    +New
                </button>
                <ng-container *ngIf="data.length">
                    <button *ngxPermissionsOnly="['full:timesheets', 'export:timesheets']" (click)="downloadData()"
                        class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                        <img *ngIf="!perpageDropdown" src='/assets/icons/timesheet/download.png'
                            style='width: 30px;height: 20px;' />
                    </button>
                </ng-container>
                <input type='file' (change)="uploadData($event)" class='hidden' #fileInput accept='.csv,.xlsx' />
                <button *ngxPermissionsOnly="['full:timesheets', 'upload:timesheets']" (click)="fileInput.click()"
                    class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                    <img *ngIf="!perpageDropdown" src='/assets/icons/timesheet/upload.png'
                        style='width: 30px;height: 20px;' />
                </button>
                <button (click)="handleGenerateInvoice()"
                    class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                    Generate Invoice
                </button>
                <div class='flex items-center text-sm text-[#2A6AF5] font-Inter gap-2 ml-3'>
                    <span>All Timesheets:&nbsp;<span class='font-bold'>{{filtered}}</span></span>
                    <span>Authorised:&nbsp;<span class='font-bold'>{{authorised}}</span></span>
                    <span>Unauthorised:&nbsp;<span class='font-bold'>{{filtered-authorised}}</span></span>
                </div>
                <div class='flex items-center text-sm text-[#2A6AF5] font-Inter gap-2 ml-3'>
                    <span>Total Hours:&nbsp;<span class='font-bold'>{{totalNetHours}}</span></span>
                    <span>Total Payment:&nbsp;<span class='font-bold'>{{totalPayment}}</span></span>
                    <span>Total Charge:&nbsp;<span class='font-bold'>{{totalCharge}}</span></span>
                    <span>Total Gross Profit:&nbsp;<span class='font-bold'>{{totalGross}}</span></span>
                </div>
            </div>
            <div *ngIf="data.length" class='flex items-center gap-2'>
                <span>
                    <button (click)="saveFilters()"
                        class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
                        Save Filters
                    </button>
                </span>
                <span class='text-xs text-[#344054] font-medium'>Show</span>
                <div class='relative w-[54px]'>
                    <div class='w-full flex gap-2 border border-solid border-[#DADADA] rounded-lg box-border p-1  bg-white hover:cursor-pointer'
                        (click)="openPerpageDropdown()">
                        {{query.perPage}}
                        <img *ngIf="!perpageDropdown" src='/assets/icons/timesheet/chevron-down.svg' />
                        <img *ngIf="perpageDropdown" src='/assets/icons/timesheet/chevron-up.svg' />
                    </div>
                    <div class='w-full flex flex-col absolute border border-solid border-[#DADADA]  rounded-lg bg-white top-9 z-50'
                        *ngIf="perpageDropdown">
                        <div *ngFor="let perpage of perPages;let i=index" (click)="handlePerpageChange(perpage)"
                            class='flex justify-center items-center p-1 hover:cursor-pointer'>
                            {{perpage}}
                        </div>
                    </div>
                </div>
                <div class='flex gap-2'>
                    <div (click)="previouspage()"
                        class='p-[6px] box-border rounded-lg flex justify-center items-center bg-[#F0F4FC] hover:cursor-pointer'>
                        <img src='/assets/icons/timesheet/chevron-left-blue.svg' class='w-5 h-5' />
                    </div>
                    <div (click)="nextpage()"
                        class='p-[6px] box-border rounded-lg flex justify-center items-center bg-[#F0F4FC] hover:cursor-pointer'>
                        <img src='/assets/icons/timesheet/chevron-right-blue.svg' class='w-5 h-5' />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='w-full box-border py-5 custom-height scroll-thin-style'>
        <div class="w-full h-full flex flex-col gap-y-2 justify-center items-center font-Inter"
            *ngIf="!(data.length > 0)">
            <img src="/assets/icons/Illustration.svg" alt="Illustration-icon" />
            <span class='text-base font-Inter font-medium'>No Data Found</span>
            <div class="w-full flex justify-center gap-x-2">
                <button class="custom-outlined-btn !w-fit" (click)="fetchData()">Try again</button>
                <button class="custom-solid-btn !w-fit" (click)="clearSearch()">
                    Clear Search
                </button>
            </div>
        </div>
        <div class='table_wraper' *ngIf="data.length > 0">
            <c-table [columns]="columnDef" [data]="data" [loading]="false" [total]="filtered" [query]="query"
                [sortedColumn]="sortedColumn" [collapsibleColumn]="true" [collapsedColumns]="savedCollapsedColumns"
                [toggleObj]="savedToggleObj" (changed)="fetchData()" [activePagination]="false"
                (sorted)="handleSort($event)"></c-table>
        </div>
    </div>
</div>



<app-loader-v2 [loading]="loading"></app-loader-v2>
<app-modal-body *ngIf="modalStatus!=='NONE'">
    <ng-container [ngTemplateOutlet]="creation_template" *ngIf="modalStatus==='OPEN_CREATION'"></ng-container>
    <ng-container [ngTemplateOutlet]="confirm_template" *ngIf="modalStatus==='DELETE_CONFIRM'"></ng-container>
    <ng-container [ngTemplateOutlet]="select_file" *ngIf="modalStatus==='SELECT_FILE'"></ng-container>
    <ng-container [ngTemplateOutlet]="import_confirmation" *ngIf="modalStatus==='IMPORT_CONFIRMATION'"></ng-container>
    <ng-container [ngTemplateOutlet]="payment_confirmation" *ngIf="modalStatus==='PAYMENT_CONFIRMATION'"></ng-container>
    <ng-container [ngTemplateOutlet]="generate_invoice_file"
        *ngIf="modalStatus==='GENERATE_INVOICE_FILE'"></ng-container>
</app-modal-body>

<ng-template #confirm_template>
    <div class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <div class='w-full flex justify-center items-center'>
                <img src="/assets/icons/offers/warning.svg" alt="delete-icon" />
            </div>
            <div class='w-full flex flex-col gap-y-[10px]'>
                <div class='w-full flex justify-center text-center font-Inter text-lg font-semibold leading-7'>
                    Are you sure you want to delete this Timesheet?
                </div>
                <div class='w-full text-center text-sm text-[#475467] font-Inter font-normal'>
                    This will delete this timesheet permanently. You cannot undo this action.</div>
                <div class='w-full flex flex-col items-center box-border py-[10px] gap-y-2 rounded-[10px]
                bg-[#F2F5FB] px-[14px]'>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>
                            Date:
                        </span>
                        {{deletingTimesheet.Date}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Driver Name:
                        </span>
                        {{deletingTimesheet.Driver}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Start Time:
                        </span>
                        {{deletingTimesheet.JobStart}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>State:
                        </span>
                        {{deletingTimesheet.Location}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Department:
                        </span>
                        {{deletingTimesheet.Department}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Vehicle:
                        </span>
                        {{deletingTimesheet.Role}}</span>
                </div>
            </div>
        </div>
        <div class='w-full flex items-center gap-2'>
            <button class='custom-outlined-btn' (click)="closeModal()">Cancel</button>
            <button
                class='w-full flex box-border px-[18px] py-[10px] justify-center items-center rounded-lg bg-[#CD4244] font-Inter text-base font-semibold outline-none border border-solid border-[#CD4244] text-white'
                (click)="deleteTimesheet(deletingTimesheet.id)">Delete</button>
        </div>
    </div>
</ng-template>


<ng-template #creation_template>
    <form [formGroup]="timesheetCreationForm" #formRef="ngForm" (ngSubmit)="createTimesheet()"
        class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='grid grid-cols-12 gap-x-4 gap-y-5'>
            <div class='col-span-12 flex flex-col justify-center items-center '>
                <span class='font-Inter text-lg text-[#101828] font-semibold'>
                    New Timesheet
                </span>
                <span class='font-Inter text-sm text-[#475467] font-normal'>
                    Please enter details.
                </span>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Driver</span>
                    </div>
                    <div class='w-full flex relative'>
                        <img src="/assets/icons/search.svg" alt="search-icon"
                            class='absolute top-[10px] right-[10px]' />
                        <input
                            class='w-full h-10 overflow-hidden flex justify-start items-center px-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-sm font-Inter'
                            placeholder='Search Driver' [matAutocomplete]="driverAuto" formControlName="driver"
                            [ngClass]="{
                                'border-[#ee4d4d]':isInvalidControl('driver'),
                                'border-[#D0D5DD]':!isInvalidControl('driver')
                              }" />
                        <mat-autocomplete #driverAuto="matAutocomplete">
                            <mat-option *ngFor="let driver of filterDrivers(drivers)" [value]="driver.FullName"
                                (onSelectionChange)="handleSelectDriver(driver)">
                                {{driver.FullName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Date</span>
                    </div>
                    <div class="w-full relative">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="offerDate"
                                    (ngModelChange)="handleOfferDateChange($event)"
                                    [ngModelOptions]="{standalone: true}" />
                                <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <input (click)="picker.open()" placeholder='DD/MM/YYYY'
                            class='w-full h-10 overflow-hidden flex px-[14px] py-[10px] border border-solid border-[#D0D5DD] rounded-lg outline-none'
                            formControlName="date" readonly />
                    </div>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Location</span>
                    </div>

                    <div class='w-full flex relative'>
                        <img src="/assets/icons/timesheet/chevron-selector-vertical.svg" alt="search-icon"
                            class='absolute top-[10px] right-[10px]' />
                        <input [ngClass]="{disabled:true}"
                            class='w-full h-10 overflow-hidden flex justify-start items-center px-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-sm font-Inter'
                            formControlName="location" [ngClass]="{
                            'border-[#ee4d4d]':isInvalidControl('location'),
                            'border-[#D0D5DD]':!isInvalidControl('location')
                          }" [matAutocomplete]="stateAuto" />
                        <mat-autocomplete #stateAuto="matAutocomplete">
                            <mat-option *ngFor="let location of businessDetails.state" [value]="location.state">
                                {{location.state}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Department</span>
                    </div>
                    <div class='w-full flex relative'>
                        <img src="/assets/icons/timesheet/chevron-selector-vertical.svg" alt="search-icon"
                            class='absolute top-[10px] right-[10px]' />
                        <input
                            class='w-full h-10 overflow-hidden flex justify-start items-center pl-[14px] pr-9 py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-sm font-Inter'
                            [matAutocomplete]="departmentAuto" formControlName="department" [ngClass]="{
                                'border-[#ee4d4d]':isInvalidControl('department'),
                                'border-[#D0D5DD]':!isInvalidControl('department')
                              }" />
                        <mat-autocomplete #departmentAuto="matAutocomplete">
                            <mat-option
                                *ngFor="let street of departments$ | async | searchFilter:timesheetCreationForm.controls.department.value"
                                [value]="street" class='!text-xs'>
                                {{street}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Role</span>
                    </div>

                    <div class='w-full flex relative'>
                        <img src="/assets/icons/timesheet/chevron-selector-vertical.svg" alt="search-icon"
                            class='absolute top-[10px] right-[10px]' />
                        <input
                            class='w-full h-10 overflow-hidden flex justify-start items-center pl-[14px] pr-9 py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-sm font-Inter'
                            [matAutocomplete]="vehicleAuto" formControlName="role" [ngClass]="{
                                    'border-[#ee4d4d]':isInvalidControl('role'),
                                    'border-[#D0D5DD]':!isInvalidControl('role')
                                  }" />
                        <mat-autocomplete #vehicleAuto="matAutocomplete">
                            <mat-option
                                *ngFor="let street of roles$ | async|searchFilter:timesheetCreationForm.controls.role.value"
                                [value]="street">
                                {{street}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Pay In</span>
                    </div>
                    <div
                        class='w-full h-10 max-h-10 flex px-[14px] py-[10px] border border-solid border-[#D0D5DD] rounded-lg '>
                        <ngx-timepicker-field [controlOnly]="true" [(ngModel)]="payintime"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="handleHoursMinsClockChange($event,'payin')"></ngx-timepicker-field>
                        <input class='hidden' formControlName="payin" />
                    </div>
                    <span class='text-[12px] font-Inter text-red-500'
                        *ngIf="timesheetCreationForm.hasError('pastTime')">*Pay in time not available.</span>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Pay Out</span>
                    </div>
                    <div
                        class='w-full h-10 max-h-10 flex px-[14px] py-[10px] border border-solid border-[#D0D5DD] rounded-lg '>
                        <ngx-timepicker-field [controlOnly]="true" [(ngModel)]="payouttime"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="handleHoursMinsClockChange($event,'payout')"></ngx-timepicker-field>
                        <input class='hidden' formControlName="payout" />
                    </div>
                    <span class='text-[12px] font-Inter text-red-500'
                        *ngIf="timesheetCreationForm.hasError('pastTime')">*Pay Out time is not available.</span>
                </div>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Timesheet Comments</span>
                    </div>
                    <div class='w-full'>
                        <textarea
                            class='w-full  border border-[#D0D5DD] border-solid text-base font-Inter px-[14px] py-[10px] rounded-lg outline-none scroll-thin-style'
                            formControlName='timesheet_comments'></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class='w-full flex items-center gap-2'>
            <button class='custom-solid-btn' type='submit' [attr.disabled]="onCreatedTimesheet ? true : null">Add</button>
        </div>
    </form>
</ng-template>


<ng-template #select_file>
    <div class="w-[400px] flex flex-col scroll-thin-style relative gap-2">
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='flex flex-col items-start justify-center'>
            <span class='font-Inter text-lg text-[#101828] font-semibold'>
                Select Departments
            </span>
            <span class='font-Inter text-sm text-[#475467] font-normal'>
                Choose those you'd like to import data for.
            </span>
        </div>
        <div class='w-full flex relative'>
            <img src="/assets/icons/search.svg" alt="search-icon" class='absolute top-[10px] right-[10px]' />
            <input
                class='w-full h-10 overflow-hidden flex justify-start items-center px-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-sm font-Inter'
                placeholder='Quick Search' [(ngModel)]="quickSearch"
                (ngModelChange)="quickSearchDepartment($event,'departments2Upload')" />
        </div>
        <div class="max-h-[300px] h-[300px] overflow-y-auto overflow-x-hidden scroll-thin-style">
            <div *ngFor="
      let row of getQuickSearchResult(departments2Upload);
      let i = index
    " class="department_loop">
                <mat-checkbox color="primary" [checked]="isChecked(row.isChecked)"
                    (change)="setDepartment2UploadCheck($event.checked, row,'departments2Upload')">
                    {{ row.Department }}
                </mat-checkbox>
            </div>
        </div>
        <button (click)="handleSelectFile()"
            class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
            Select File
        </button>
    </div>
</ng-template>


<ng-template #import_confirmation>
    <div class="w-[400px] flex flex-col scroll-thin-style relative gap-2">
        <div class='flex flex-col items-start justify-center'>
            <span class='font-Inter text-lg text-[#101828] font-semibold'>
                Import Complete!
            </span>
        </div>
        <div class='flex flex-col items-start'>
            <span class='text-sm'><span class='font-bold mr-1'>{{importCompletion.total}}</span>total Timesheets</span>
            <span class='text-sm'><span class='font-bold mr-1'>{{importCompletion.success}}</span>successfully
                updated</span>
            <span class='text-sm'><span class='font-bold mr-1'>{{importCompletion.error}}</span>error(s)
                returned:</span>
        </div>

        <div *ngIf="importCompletion.error"
            class="max-h-[300px] h-[300px] overflow-y-auto overflow-x-hidden scroll-thin-style">
            <div *ngFor="
      let error of excelDataErrors;
      let i = index
    " class="department_loop">
                <span class='text-sm'>•&nbsp;{{error.column}},&nbsp;row&nbsp;{{error.row}}</span>
            </div>
        </div>
        <button (click)="closeModal()"
            class='flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2'>
            Confirm
        </button>
    </div>
</ng-template>

<ng-template #payment_confirmation>
    <div class='w-[400px] flex flex-col items-start box-border relative gap-y-4'>
        <!-- <div class="w-full relative">
            <div class='_hide_item'>
                <mat-form-field>
                    <mat-date-range-input [rangePicker]="picker2" [max]="maxDate"
                        (ngModelChange)='onRangeDateChange($event)' (dateChange)='onRangeDateChange($event)'
                        (change)='onRangeDateChange($event)'>
                        <input matStartDate (ngModelChange)='onRangeDateChange($event)'
                            [(ngModel)]='payRange.startRange'>
                        <input matEndDate (ngModelChange)='onRangeDateChange($event)' [(ngModel)]='payRange.endRange'>
                    </mat-date-range-input>
                    <mat-date-range-picker #picker2>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>
                                Apply
                            </button>
                        </mat-datepicker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class='w-full flex items-center'>
                <div class='w-full grid grid-cols-2'>
                    <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                        <span class="date_selection_date">{{
                            getFormattedTime(payRange.startRange)
                            }}</span>
                    </span>
                    <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                        <span class="date_selection_date">{{
                            getFormattedTime(payRange.endRange)
                            }}</span>
                    </span>
                </div>
                <button (click)="picker2.open()" class="date_select_button">
                    <img src="/assets/icons/calendar.svg" alt="search-icon" />
                </button>
            </div>
        </div> -->

        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex justify-start items-center font-Inter text-lg text-[#101828] font-semibold'>
            Select Period to Process
        </div>
        <div class='w-full'>
            <div class='w-full flex items-center border border-solid border-[#D0D5DD] rounded'>
                <div class='w-fit flex justify-center items-center hover:cursor-pointer' (click)="previousWeek()">
                    <img src='/assets/icons/timesheet/chevron-left-blue.svg' style='width: 30px;height: 20px;' />
                </div>
                <div
                    class='flex flex-1 justify-center items-center border-solid border-x border-[#D0D5DD] box-border p-2 font-Inter text-base font-semibold text-[#101828]'>
                    {{rangeDescriptionGen()}}</div>
                <!-- <div class='w-fit flex justify-center items-center hover:cursor-pointer'
                    [ngClass]="{'opacity-60':isAfterToday(payRange.endRange)}"
                    (click)="!isAfterToday(payRange.endRange)&&nextWeek()">
                    <img src='/assets/icons/timesheet/chevron-right-blue.svg'
                        style='width: 30px;height: 20px;' />
                </div> -->
                <div class='w-fit flex justify-center items-center hover:cursor-pointer' (click)="nextWeek()">
                    <img src='/assets/icons/timesheet/chevron-right-blue.svg' style='width: 30px;height: 20px;' />
                </div>
            </div>
        </div>

        <div class='w-full flex items-center gap-2'>
            <button class='custom-outlined-btn' (click)="closeModal()">Cancel</button>
            <button
                class='w-full flex box-border px-[18px] py-[10px] justify-center items-center rounded-lg bg-[#2A6AF5] font-Inter text-base font-semibold outline-none border border-solid border-[#2A6AF5] text-white'
                (click)="processPayments()">Process Pay</button>
        </div>
    </div>
</ng-template>

<ng-template #generate_invoice_file>
    <div class="w-[400px] flex flex-col scroll-thin-style relative gap-2">
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='flex flex-col items-start justify-center'>
            <span class='font-Inter text-lg text-[#101828] font-semibold'>
                Generate Invoice File
            </span>
        </div>
        <div class='flex flex-wrap'>
            <div class="w-full flex gap-2 items-center">
                <div
                    class="w-full flex whitespace-nowrap relative justify-center items-center gap-4 rounded-lg bg-[#ececec] p-[10px]">
                    <div class="hide_item">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="payRange.startRange"
                                (ngModelChange)="fromPayrangeChange($event)" />
                            <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                    <button mat-button matDatepickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDatepickerApply>
                                        Apply
                                    </button>
                                </mat-datepicker-actions></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class='w-full flex items-center justify-between'>
                        <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(payRange.startRange)
                                }}</span>
                        </span>
                        <button (click)="picker.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                </div>
                <div
                    class="w-full flex whitespace-nowrap relative justify-center items-center gap-4 rounded-lg bg-[#ececec] p-[10px]">
                    <div class="hide_item">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker1" [(ngModel)]="payRange.endRange"
                                (ngModelChange)="toPayrangeChange($event)" />
                            <mat-datepicker #picker1><mat-datepicker-actions>
                                    <button mat-button matDatepickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDatepickerApply>
                                        Apply
                                    </button>
                                </mat-datepicker-actions></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class='w-full flex items-center justify-between'>
                        <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(payRange.endRange)
                                }}</span>
                        </span>
                        <button (click)="picker1.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class='font-Inter text-sm text-[#101828] font-normal'>
            <mat-checkbox color="primary" [checked]="false"
                (change)="setLastWeek($event.checked)">
                Last Week
              </mat-checkbox>
        </div>
        <div class='font-Inter text-sm text-[#101828] font-normal'>
            Select Department(s)
        </div>
        <div class='w-full flex relative'>
            <img src="/assets/icons/search.svg" alt="search-icon" class='absolute top-[10px] right-[10px]' />
            <input
                class='w-full h-10 overflow-hidden flex justify-start items-center px-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-sm font-Inter'
                placeholder='Quick Search' [(ngModel)]="quickSearch"
                (ngModelChange)="quickSearchDepartment($event,'seekedDepartments')" />
        </div>
        <div class="max-h-[300px] h-[300px] overflow-y-auto overflow-x-hidden scroll-thin-style">
            <div *ngFor="
      let row of getQuickSearchResult(seekedDepartments);
      let i = index
    " class="department_loop">
                <mat-checkbox color="primary" [checked]="isChecked(row.isChecked)"
                    (change)="setDepartment2UploadCheck($event.checked, row,'seekedDepartments')">
                    {{ row.Department }}
                </mat-checkbox>
            </div>
        </div>
        <div class='w-full flex items-center gap-2'>
            <button class='custom-outlined-btn' (click)="closeModal()">Cancel</button>
            <button
                class='w-full flex box-border px-[18px] py-[10px] justify-center items-center rounded-lg bg-[#2A6AF5] font-Inter text-base font-semibold outline-none border border-solid border-[#2A6AF5] text-white'
                (click)="generateClientInvoice()">Generate</button>
        </div>
    </div>
</ng-template>