import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit
} from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { LngLatLike, Map, SymbolLayout } from 'mapbox-gl';
import moment from 'moment';
import {
  LocationDataFilterKeyType,
  LocationDataType,
} from 'src/app/model/custm/LocationData';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { LocationDataService } from 'src/app/services/custm/location-data.service';
import { AddressGeocoder } from 'src/app/services/custm/address-geocoder.service';

@Component({
  selector: 'app-location-data',
  templateUrl: './location-data.component.html',
  styleUrls: ['./location-data.component.scss'],
})
export class LocationDataComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isClickOutsidePopup(event)) {
      this.cursorStyle = '';
      this.activePopup = 'none';
    }
  }

  minValue: number = 0;
  maxValue: number = 48;
  options: Options = {
    floor: 0,
    ceil: 48,
    step: 1,
    minRange: 1,
    pushRange: true,
    showTicks: true,
    translate: (value: number) =>
      moment()
        .startOf('day')
        .add(value * 30, 'minutes')
        .format('HH:mm'),
    getLegend: (value: number): string =>
      value % 2 === 0
        ? moment()
            .startOf('day')
            .add(value * 30, 'minutes')
            .format('HH:mm')
        : '',
  };
  paint = { 'background-color': 'green', 'background-opacity': 0.1 };
  timer: any;
  _features: any[] = [];

  copyStatus: boolean = false;
  focus: string = '';
  shiftType: string = 'dedi';
  shiftId: number = 0;
  labelLayerId!: string;
  geoJsonData: any = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },

    features: [],
  };
  geoStopsJsonData: any = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },

    features: [],
  };
  depotData: any = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },

    features: [],
  };
  clockingData: any = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },

    features: [],
  };
  selectedPoint: any;
  cursorStyle: string = '';
  name: string = '';
  email: string = '';
  departmentSearch: string = '';
  roleSearch: string = '';
  driverSearch: string = '';
  targetDate: Date = new Date();
  center: LngLatLike = [150.8648836, -33.7964387];
  selected: any = {};

  screenCoordinate = { x: 0, y: 0 };
  activePopup: string = 'none';
  activeStopPopup: string = 'none';

  public allImagesLoaded = false;
  imageLoadedCount = 0;

  public filterOptionData: {
    state: LocationDataType[];
    department: LocationDataType[];
    role: LocationDataType[];
    driver: LocationDataType[];
  } = {
    state: [],
    department: [],
    role: [],
    driver: [],
  };

  public prevFilterOptionData: {
    state: LocationDataType[];
    department: LocationDataType[];
    role: LocationDataType[];
    driver: LocationDataType[];
  } = {
    state: [],
    department: [],
    role: [],
    driver: [],
  };

  filteredData: {
    state: string[];
    department: string[];
    role: string[];
    driver: number | undefined;
  } = {
    state: [],
    department: [],
    role: [],
    driver: undefined,
  };

  tempFilteredData: {
    state: string[];
    department: string[];
    role: string[];
    driver: number | undefined;
  } = {
    state: [],
    department: [],
    role: [],
    driver: undefined,
  };

  public isExpanded = {
    state: false,
    department: false,
    role: false,
    driver: false,
    drawer: false,
  };

  private map!: Map;

  constructor(
    private locationDataService: LocationDataService,
    private cdref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private addressGeocoder: AddressGeocoder
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p?.user_id) {
        this.targetDate = new Date(p.date);
        this.filteredData.driver = Number(p.user_id);
        this.focus = p.focus;
        this.shiftType = p.shift_type ?? 'dedi';
        this.shiftId = Number(p.Shift_ID);
      }
    });
    this.fetchStates();
    this.fetchData();
    this.getUserTrackingData();
  }

  ngOnInit(): void {}

  checkImagesLoaded() {
    this.imageLoadedCount++;
    if (this.imageLoadedCount === 2) {
      this.allImagesLoaded = true;
    }
  }
  onLoad(mapInstance: Map) {
    this.map = mapInstance;
    const layers = mapInstance.getStyle().layers!;

    for (let i = 0; i < layers.length; i++) {
      if (
        layers[i].type === 'symbol' &&
        ((<SymbolLayout>layers[i]) as any).layout['text-field']
      ) {
        this.labelLayerId = layers[i].id;
        break;
      }
    }
  }

  onMouseEnter(evt: any) {
    const features = this.map.queryRenderedFeatures(evt.point, {
      layers: ['unclustered-point', 'unclustered-point-clocking'],
    });

    const hasClockingFeature = features.some(
      (feature) => feature.layer.id === 'unclustered-point-clocking'
    );

    if (!hasClockingFeature) {
      this.screenCoordinate = {
        x: evt.originalEvent.clientX,
        y: evt.originalEvent.clientY,
      };
      this.activePopup = 'none';
      this.activeStopPopup = 'normal';
      this.cursorStyle = 'pointer';

      const localTime = moment
        .utc(evt.features[0].properties.timestamp)
        .utcOffset(this.selected.tz.timezone)
        .format('llll');

      this.selected.timestamp = localTime;
      this.selectedPoint = null;
      this.cdref.detectChanges();
      this.selectedPoint = evt.lngLat;
    }
  }

  onCommonMouseEnter(evt: any, active: string) {
    this.screenCoordinate = {
      x: evt.originalEvent.clientX,
      y: evt.originalEvent.clientY,
    };
    this.activePopup = active;
    this.cursorStyle = 'pointer';
    this.selected = { ...this.selected, ...evt.features[0].properties };
    this.selectedPoint = null;
    this.cdref.detectChanges();
    this.selectedPoint = evt.lngLat;
  }

  onMouseLeave() {
    this.cursorStyle = '';
    this.activeStopPopup = 'none';
  }

  onStopMouseEnter(evt: any) {
    this.screenCoordinate = {
      x: evt.originalEvent.clientX,
      y: evt.originalEvent.clientY,
    };
    this.activePopup = 'none';
    this.activeStopPopup = 'stop';
    this.cursorStyle = 'pointer';
    this.selected = { ...this.selected, ...evt.features[0].properties };

    this.selected.arrival_time = this.selected.arrival_time
      ? moment
          .utc(evt.features[0].properties.arrival_time)
          .utcOffset(this.selected.tz.timezone)
          .format('llll')
      : '';
    this.selectedPoint = null;
    this.cdref.detectChanges();
    this.selectedPoint = evt.lngLat;
  }

  getUserTrackingData() {
    // const data = moment(this.targetDate).format('YYYY-MM-DD HH:mm:ss');
    if (this.filteredData.driver) {
      this.geoJsonData.features = [];
      this.geoStopsJsonData.features = [];
      this.depotData.features = [];
      this.clockingData.features = [];
      this.locationDataService
        .GetUserTrackingData(
          this.targetDate.toDateString(),
          this.filteredData.driver, 
          this.shiftType == 'flexi' ? this.shiftId : null,
        )
        .subscribe(async (res) => {
          if ((res?.data?.data ?? []).length <= 0) {
            NotificatorPartial.push({
              type: 'info',
              message: 'No data for the user!!!',
              timeout: 3000,
            });
            return;
          }
          this.selected.name = res?.data?.name ?? '';
          this.selected.email = res?.data?.email ?? '';
          this.selected.tz = res?.data?.tz;
          this.geoStopsJsonData.features = (res?.data?.stops ?? []).map(
            (item: any, itemIndex: number) => ({
              type: 'Feature',
              properties: {
                address: item.address ?? '',
                stop_type: item.stop_type ?? '',
                arrival_time: item.arrival_time ?? '',
                sequence: item.sequence ?? '',
                stop_result: item.stop_result ?? '',
                stop_outcome: item.stop_outcome ?? '',
                business_name: item.business_name ?? '',
              },
              geometry: {
                type: 'Point',
                coordinates: [item.geo.lng, item.geo.lat],
              },
            })
          );

          this.depotData.features = (res?.data?.clocked_coords ?? []).flatMap(
            (item: any, itemIndex: number) => {
              if (item?.depot) {
                return [
                  {
                    type: 'Feature',
                    properties: {
                      depot: item.depot.name,
                      depot_lng: item.depot.lng,
                      depot_lat: item.depot.lat,
                    },
                    geometry: {
                      type: 'Point',
                      coordinates: [item.depot.lng, item.depot.lat],
                    },
                  },
                ];
              }
              return [];
            }
          );

          this.clockingData.features = (
            res?.data?.clocked_coords ?? []
          ).flatMap((item: any, itemIndex: number) => {
            if (item?.in || item?.out) {
              const features: any[] = [];
              if (item?.in)
                features.push({
                  type: 'Feature',
                  properties: {
                    time_label: 'Clock in time :',
                    time: moment(item?.in.time).format('hh:mm a'),
                    check: item.in.check,
                    check_label: 'In check :',
                    clocking_lng: item.in.lng.toFixed(6),
                    clocking_lat: item.in.lat.toFixed(6),
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: [item.in.lng, item.in.lat],
                  },
                });
              if (item?.out)
                features.push({
                  type: 'Feature',
                  properties: {
                    time_label: 'Clock out time :',
                    time: moment(item?.out.time).format('hh:mm a'),
                    check: item.out.check,
                    check_label: 'Out check :',
                    clocking_lng: item.out.lng.toFixed(6),
                    clocking_lat: item.out.lat.toFixed(6),
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: [item.out.lng, item.out.lat],
                  },
                });

              return features;
            }
            return [];
          });

          this.geoJsonData.features = (res?.data?.data ?? []).map(
            (item: any, itemIndex: number) => ({
              type: 'Feature',
              properties: {
                timestamp: item.timestamp,
              },
              geometry: { type: 'Point', coordinates: [item.lng, item.lat] },
            })
          );
          this._features = _.cloneDeep(this.geoJsonData.features);

          // Separate the implementation for plotting FLEXI locations because we still need to geocode the addresses.
          await this.getFlexiStops(res?.data?.flexi_stops ?? []);
          // Reset the shift type since we only need it once (Location data opened in Flexi Offers).
          this.shiftType = 'dedi';

          if (this.depotData.features.length && !this.focus)
            this.center = this.depotData.features[0].geometry
              .coordinates as LngLatLike;
          if (!!this.focus) {
            const found = (res?.data?.clocked_coords ?? []).find(
              (item: any) => item.Shift_ID === this.shiftId
            );

            this.center = [found[this.focus].lng, found[this.focus].lat];
            this.focus = '';
          }
        });
    }
  }

  async getFlexiStops(flexiStops: any) {
    if (flexiStops.length > 0) {
      // Get pick up address from first item.
      const pickUpAddress = flexiStops[0].pickup_full_address;
      const pickUpLatLong = await this.addressGeocoder.geoCodeAddress(pickUpAddress);
  
      if (pickUpLatLong !== null) {
        this.depotData.features.push({
          type: 'Feature',
          properties: {
            isFlexi: true,
            flexi_pickup_address: pickUpAddress,
          },
          geometry: {
            type: 'Point',
            coordinates: [pickUpLatLong.lng, pickUpLatLong.lat],
          },
        });
      }
  
      // Geocode drop off addresses as stops.
      const dropOffPromises = flexiStops.map(async (item: any) => {
        const dropoffAddress = item.dropoff_full_address;
        const dropOffLatLong = await this.addressGeocoder.geoCodeAddress(dropoffAddress);
  
        if (dropOffLatLong !== null) {
          return {
            type: 'Feature',
            properties: {
              isFlexi: true,
              flexi_address: item.dropoff_full_address ?? '',
              sequence: item.stops_sort_id ?? '',
            },
            geometry: {
              type: 'Point',
              coordinates: [dropOffLatLong.lng, dropOffLatLong.lat],
            },
          };
        }
        return null;
      });
  
      const dropOffFeatures = await Promise.all(dropOffPromises);
  
      // Filter out any null results and add to geoStopsJsonData
      this.geoStopsJsonData.features.push(...dropOffFeatures.filter(feature => feature !== null));
    }
  }

  fetchData() {
    this.fetchDepartments();
    this.fetchRoles();
    this.fetchDrivers();
  }

  fetchStates() {
    this.locationDataService
      .GetStates(this.targetDate.toDateString())
      .subscribe((res) => {
        const checked = this.filterOptionData.state
          .filter((item) => item.isChecked)
          .map((item) => item.state);

        const visible = this.filterOptionData.state
          .filter((item) => item.isVisible)
          .map((item) => item.state);

        this.filterOptionData.state = (res?.data ?? []).map((item: string) => ({
          state: item,
          isChecked: checked.includes(item),
          isVisible: visible.includes(item),
        }));
      });
  }

  fetchDepartments() {
    this.locationDataService
      .GetDepartments(this.targetDate.toDateString(), this.filteredData.state)
      .subscribe((res) => {
        const checked = this.filterOptionData.department
          .filter((item) => item.isChecked)
          .map((item) => item.department);

        const visible = this.filterOptionData.department
          .filter((item) => item.isVisible)
          .map((item) => item.department);

        this.filterOptionData.department = (res?.data ?? []).map(
          (item: string) => ({
            department: item,
            isChecked: checked.includes(item),
            isVisible: this.filterOptionData.department.length
              ? visible.includes(item)
              : true,
          })
        );
      });
  }

  fetchRoles() {
    this.locationDataService
      .GetRoles(
        this.targetDate.toDateString(),
        this.filteredData.state,
        this.filteredData.department
      )
      .subscribe((res) => {
        const checked = this.filterOptionData.role
          .filter((item) => item.isChecked)
          .map((item) => item.role);

        const visible = this.filterOptionData.role
          .filter((item) => item.isVisible)
          .map((item) => item.role);
        this.filterOptionData.role = (res?.data ?? []).map((item: string) => ({
          role: item,
          isChecked: checked.includes(item),
          isVisible: this.filterOptionData.role.length
            ? visible.includes(item)
            : true,
        }));
      });
  }

  fetchDrivers() {
    this.locationDataService
      .GetDrivers(
        this.targetDate.toDateString(),
        this.filteredData.state,
        this.filteredData.department,
        this.filteredData.role
      )
      .subscribe((res) => {
        this.filterOptionData.driver = (res?.data?.drivers ?? []).map(
          (item: any) => ({
            driver: `${item.FirstName} ${item.LastName}`,
            userId: item.UserID,
            isVisible: true,
          })
        );
        if (this.filteredData.driver) {
          if (
            !this.filterOptionData.driver
              .map((item) => item.userId)
              .includes(this.filteredData.driver)
          ) {
            this.filteredData.driver = undefined;
            this.tempFilteredData.driver = undefined;
          }
        }
      });
  }

  public handleVisivility(
    key: 'state' | 'department' | 'role' | 'driver' | 'drawer',
    value: boolean | null = null
  ) {
    // If the user close filter window without apply, it will ignore what the user newly checked.
    if (key === 'drawer' && value === false) {
      this.filteredData = { ...this.tempFilteredData };
      this.filterOptionData.state.forEach((item, itemIndex) => {
        this.filterOptionData.state[itemIndex].isChecked =
          this.tempFilteredData.state.includes(item.state);
      });
      this.filterOptionData.role.forEach((item, itemIndex) => {
        this.filterOptionData.role[itemIndex].isChecked =
          this.tempFilteredData.role.includes(item.role);
      });
      this.filterOptionData.department.forEach((item, itemIndex) => {
        this.filterOptionData.department[itemIndex].isChecked =
          this.tempFilteredData.department.includes(item.department);
      });
      this.filteredData.driver = this.tempFilteredData.driver;
    }

    // It does expand action for state, department in filter and filter modal.
    if (value !== null) {
      this.isExpanded[key] = value;
      return;
    }
    this.isExpanded[key] = !this.isExpanded[key];
  }

  // This function define actions when user click apply button in filter modal.
  public handleFilterApply() {
    this.isExpanded.drawer = false;
    this.tempFilteredData = { ...this.filteredData };
    this.prevFilterOptionData = { ...this.filterOptionData };
    // this.reset();
    // this.fetchData();
    if (this.filteredData.driver) this.getUserTrackingData();
    else
      NotificatorPartial.push({
        type: 'info',
        message: 'Please select a user you want to trace.',
        timeout: 3000,
      });
  }

  // Check data and returns its boolean value
  public isChecked(value: boolean | undefined | null) {
    return Boolean(value);
  }

  // Clear all checks from filter modal
  public handleClearAllChecked() {
    this.filteredData.driver = undefined;
    const keyArr = ['state', 'department', 'role'];
    keyArr.forEach((item) => {
      this.filterOptionData[item as LocationDataFilterKeyType] =
        this.filterOptionData[item as LocationDataFilterKeyType].map(
          (item) => ({
            ...item,
            isChecked: false,
          })
        );
      this.filteredData[item as LocationDataFilterKeyType] = [] as string[];
      this.tempFilteredData[item as LocationDataFilterKeyType] = [] as string[];
      return true;
    });
    this.prevFilterOptionData = { ...this.filterOptionData };
    this.fetchData();
  }

  // Set isChecked value based on user control in filter modal for state and department checkboxes.
  public setFilters(
    checked: boolean,
    key: 'state' | 'department' | 'role',
    value: LocationDataType
  ) {
    const selectedIndex = this.filterOptionData[key].findIndex(
      (item) => item[key] === value[key]
    );
    this.filterOptionData[key][selectedIndex].isChecked = checked;
    this.filteredData[key].push(value[key]);

    if (key === 'state') {
      this.fetchData();
    }
    if (key === 'department') {
      this.fetchRoles();
      this.fetchDrivers();
    }
    if (key === 'role') {
      this.fetchDrivers();
    }
    this.prevFilterOptionData = { ...this.filterOptionData };
  }
  // Difine actions for user inputs in Quick serach in filter modal.
  public searchFilterOption(
    value: string,
    keyValue: 'state' | 'department' | 'role' | 'driver'
  ) {
    this.filterOptionData[keyValue].forEach((item, itemIndex) => {
      if (`${item[keyValue]}`.toLowerCase().includes(value.toLowerCase())) {
        this.filterOptionData[keyValue][itemIndex].isVisible = true;
      } else {
        this.filterOptionData[keyValue][itemIndex].isVisible = false;
      }
    });
  }

  public filterData(arr: LocationDataType[]) {
    return arr.filter(
      (item) => item?.isVisible === undefined || item?.isVisible === true
    );
  }

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date) {
    return moment(value).format('DD/MM/YY');
  }
  // Define action for fromdate change
  public handleTargetDateChange(e: string) {
    this.fetchStates();
    this.fetchData();
    if (this.filteredData.driver) this.getUserTrackingData();
    else
      NotificatorPartial.push({
        type: 'info',
        message: 'Please select a user you want to trace.',
        timeout: 3000,
      });
  }

  selectDriver(userId: number) {
    this.filteredData.driver = userId;
  }
  onUserChange(range: ChangeContext) {
    if (!!this._features.length) {
      const selectedDate = new Date(this._features[0]?.properties?.timestamp);
      const startTime =
        moment(selectedDate)
          .startOf('day')
          .add(range.value * 30, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss') + this.selected.tz.timezone;
      const utcStart = moment.utc(startTime);
      const endTime =
        moment(selectedDate)
          .startOf('day')
          .add((range.highValue ?? 0) * 30, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss') + this.selected.tz.timezone;
      const utcEnd = moment.utc(endTime);
      const temp = this._features.filter((item) => {
        const given = moment.utc(item.properties.timestamp);
        return given.isSameOrAfter(utcStart) && given.isSameOrBefore(utcEnd);
      });
      if (this.timer) clearTimeout(this.timer);
      this.geoJsonData.features = [];
      this.timer = setTimeout(() => {
        this.geoJsonData.features = temp;
      }, 100);
    }
  }  
  private isClickOutsidePopup(event: MouseEvent): boolean {
    const popupElement = document.querySelector('app-popup');
    if (popupElement) {
      return !popupElement.contains(event.target as Node);
    }
    return true;
  }
  public copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.copyStatus = true;
      setTimeout(() => {
        this.copyStatus = false;
      }, 3000);
    });
  }
}
