import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { MenuComponent } from 'src/app/partials/menu/menu.component';
import { LoginService } from '../login.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private jwt: JwtHelperService,
    private auth: LoginService,
    private router: Router,
    private permissionService: NgxPermissionsService
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot) {
    let tokenExpired = this.jwt.isTokenExpired();
    if (!tokenExpired) {
      this.auth.refreshToken().subscribe(
        (res) => {
          let response = CodedResponseModel.decode(res);
          localStorage.setItem('access_token', response);
          const role: string = this.auth.getUserRole();
          const permissions: string[] = this.auth.getUserPermissions();
          this.permissionService.loadPermissions([role, ...permissions]);
          MenuComponent.display(true);
        },
        (err) => {
          localStorage.removeItem('access_token');
          this.router.navigate(['/login']);
          MenuComponent.display(false);
        }
      );
    } else {
      localStorage.removeItem('access_token');
      this.router.navigate(['/login']);
      MenuComponent.display(false);
      return false;
    }
    return !tokenExpired;
  }
}
