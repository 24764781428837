import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { Router } from '@angular/router';
import { MenuComponent } from '../partials/menu/menu.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public email: string = '';
  public password: string = '';

  public register_name: string = '';
  public register_email: string = '';

  public errMsg: string = '';

  constructor(
    private loginService: LoginService,
    private router: Router,
  ) {
    if (this.loginService.isLoggedIn()) {
      this.router.navigate(['/']);
    } else {
      MenuComponent.display(false);
    }
  }

  ngOnInit(): void {}

  public login() {
    this.errMsg = '';
    if (!this.email || !this.password) {
      this.errMsg = 'Please fill out both of the fields';
      return;
    }
    this.loginService.login(this.email, this.password).subscribe(
      (response) => {
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        localStorage.setItem('access_token', orginalResponse.access_token);
        this.router.navigate(['/']);
      },
      (err) => {
        console.log(err);
        if (err.error.error == 'Unauthorized')
          this.errMsg =
            'Login failed. Please verify whether your login credentials are correct';
        else
          this.errMsg =
            err.error.email ?? err.error.password ?? err.error.error;
      }
    );
  }
}
