import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EpEncryptionService } from '../ep-encryption.service';
import { IndexQuery, buildParams } from 'src/app/model/IndexQuery';
import { Observable } from 'rxjs';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { apiUrls } from 'src/app/settings/settings';

@Injectable({
  providedIn: 'root',
})
export class LocationDataService {
  constructor(
    private http: HttpClient,
    private epEncryptionService: EpEncryptionService
  ) {}

  public GetUserTrackingData(
    date: string,
    userId: number,
    flexiManifestId: any = null
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}location/tracking-data`,
      this.epEncryptionService.sendPayload({ date, userId , flexiManifestId})
    );
  }

  public GetStates(date: string): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}location/state-data`,
      this.epEncryptionService.sendPayload({ date })
    );
  }

  public GetDepartments(
    date: string,
    states: string[]
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}location/department-data`,
      this.epEncryptionService.sendPayload({ date, states })
    );
  }

  public GetRoles(
    date: string,
    states: string[],
    departments: string[]
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}location/role-data`,
      this.epEncryptionService.sendPayload({ date, states, departments })
    );
  }

  public GetDrivers(
    date: string,
    states: string[],
    departments: string[],
    roles: string[]
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}location/driver-data`,
      this.epEncryptionService.sendPayload({ date, states, departments, roles })
    );
  }
}
